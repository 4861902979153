// Libraries
import * as React from 'react'

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

const SocialMedia = ({pageContent}) => {
	return (
		<div className='social-media'>
			<a href={pageContent?.twitter} target="_blank" rel="noreferrer" className="footer-icons__link">
				<FontAwesomeIcon icon={faXTwitter} title="X (Twitter) logo" />
				<span className="sr-only"> X (Twitter)</span>
			</a>

			<a href={pageContent?.facebook} target="_blank" rel="noreferrer" className="footer-icons__link">
				<FontAwesomeIcon icon={faFacebookF} title="Facebook logo" />
				<span className="sr-only"> Facebook</span>
			</a>

			<a href={pageContent?.instagram} target="_blank" rel="noreferrer" className="footer-icons__link">
				<FontAwesomeIcon icon={faInstagram} title="Instagram logo"/>
				<span className="sr-only"> Instagram</span>
			</a>
		</div>
	)
}

export default SocialMedia
