
// Libraries
import * as React from 'react'
import {graphql} from 'gatsby'

// Services
import ContactService from '../services/contact'

// Components
import Layout from '../components/layout'
import DuoSection from '../components/duoSection'
import Seo from '../components/seo'
import SocialMedia from '../components/socialMedia'
import Button from '../components/button'
import InputFactory from '../components/inputFactory'
import DonateCta from '../components/donateCta'

class Contact extends React.Component {
	state = {
		inputValues: {},
		inputErrors: {},
		isSubmitting: false,
		submitText: 'Send',
	}

	render() {
		const {inputValues, inputErrors, isSubmitting, submitText} = this.state
		const {data} = this.props
		const pageContent = data?.contentManagement?.content

		return (
			<Layout className="page--contact nav-blue-half">
				<Seo title="Contact" />
				<DuoSection Contact>
					<div className='contact-info'>
						<div className='contact-container'>
						<h1>{pageContent?.title}</h1>
							<div className='content cms' dangerouslySetInnerHTML={{__html: pageContent?.content}} />
							<SocialMedia pageContent={pageContent} />
						</div>
					</div>
					<div className='contact-form-container'>
						<form onSubmit={this.handleSubmit} className="contact-form">
							<InputFactory
								label="Name*"
								type="text"
								name="name"
								placeholder="Enter your name"
								value={inputValues?.name ?? ''}
								error={inputErrors?.name}
								onChange={this.handleInputChange}
							/>
							<InputFactory
								label="Email address*"
								type="email"
								name="email"
								placeholder="Enter your email address"
								value={inputValues?.email ?? ''}
								error={inputErrors?.email}
								onChange={this.handleInputChange}
							/>
							<InputFactory
								label="Subject*"
								type="text"
								name="subject"
								placeholder="Enter a subject"
								value={inputValues?.subject ?? ''}
								error={inputErrors?.subject}
								onChange={this.handleInputChange}
							/>
							<InputFactory
								label="Enquiry*"
								type="textarea"
								name="enquiry"
								placeholder="Your enquiry"
								value={inputValues?.enquiry ?? ''}
								error={inputErrors?.enquiry}
								onChange={this.handleInputChange}
							/>
							<Button type="submit" isLoading={isSubmitting}>{submitText}</Button>
						</form>
					</div>
				</DuoSection>
				<DonateCta />
			</Layout>
		)
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true,
			submitText: 'Sending',
		})

		const {inputValues} = this.state
		const contactService = new ContactService()

		// submit form data
		const formData = contactService.formData(inputValues)
		await contactService.post(formData)
			.then(async (apiResponse) => {
				if (apiResponse.success) {
					this.setState({
						inputValues: {},
						inputErrors: {},
						submitText: 'Message Sent',
					}, () => {
						setTimeout(() => {
							this.setState({
								isSubmitting: false,
								submitText: 'Send',
							})
						}, 1000)
					})
				}
				else {
					this.setState({
						inputErrors: apiResponse.errors,
						isSubmitting: false,
						submitText: 'Resend',
					})
				}
				submitEvent.target.getElementsByClassName('button')[0].blur()
			})
			.catch(err => console.error(err))
	}
}

export default Contact

export const query = graphql`
{
	contentManagement(slug: {eq: "contact"}) {
		id
		content {
			title
			content
			twitter
			facebook
			instagram
		}
	}
}
`
