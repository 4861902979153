
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class ContactService {
	async post(formData = {}) {
		return await apiService.post(`form-response/contact`, {body: formData})
	}

	formData(inputValues) {
		return JSON.stringify(inputValues)
	}
}
